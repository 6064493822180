

// import { tns } from "tiny-slider/src/tiny-slider"

const { speed } = require("jquery");

// const device = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0) <= 991 ? 'mobile' : 'desktop';

// let videoIsFullscreen = 0;

// const smoothScroll = (h) => {
//     let i = h || 0;
//     if (i < 200) {
//         setTimeout(() => {
//             window.scrollTo(500, i);
//             smoothScroll(i + 10);
//         }, 10);
//     }
// }

// $(window).on('load', function() {

//     // google map custom switch
//     // $('.classic').on('click', function(){
//     //     $(this).removeClass('active');
//     //     $('#googleMap2').hide();
//     //     $('#googleMap1').show();
//     //     $('.satellite').addClass('active');
//     // });

//     // $('.satellite').on('click', function(){
//     //     $(this).removeClass('active');
//     //     $('#googleMap1').hide();
//     //     $('#googleMap2').show();
//     //     $('.classic').addClass('active');
//     // });


//     // event listener for video fullscreen
//     //document.getElementById("video").addEventListener('fullscreenchange', handleReload)
//     //document.getElementById("video").addEventListener('webkitfullscreenchange', handleReload)

// });

// // function which handle reload with little workaround, because making fullscreen video also triggerd resize so this takes care of that
// function handleReload(e) {
//     let isFullscreenNow = !!(document.fullscreenElement || document.msFullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement || document.webkitCurrentFullScreenElement || document.webkitIsFullScreen); // BUG: does not work on macOS
    
//     if(isFullscreenNow) {
//         videoIsFullscreen = 1;
//     }

//     if(videoIsFullscreen == 0) {
//         if (window.RT) clearTimeout(window.RT);
//         window.RT = setTimeout(function() {
//             window.scrollTo(0, 0);
//             this.location.reload(false);
//         }, 100);
//     }

//     if(videoIsFullscreen == 1 && !isFullscreenNow) {
//         videoIsFullscreen = 0;
//     }
// }


// // smooth scroll for navigation between blocks
// $(document).on('click', 'a[href^="#"]', function (event) {
//     event.preventDefault();

//     $('html, body').animate({
//         scrollTop: $($.attr(this, 'href')).offset().top
//     }, 500);
// });


// // check if element is in viewport
// function inViewport (el) {
//     let r, html;
//     if ( !el || 1 !== el.nodeType ) { return false; }
//     html = document.documentElement;
//     r = el.getBoundingClientRect();

//     return ( !!r
//       && r.bottom >= 0
//       && r.right >= 0
//       && r.top <= html.clientHeight
//       && r.left <= html.clientWidth
//     );
// }

// // check if sidebar is closed
// function sidebarsClosed() {
//     if(document.getElementsByClassName('gdpr-open').length == 0 &&
//        document.getElementsByClassName('cv-open').length == 0 &&
//        document.getElementsByClassName('contact-panel-open').length == 0
//     ) {
//         return true;
//     }
// }

// //scrolling
// let scrollDisable = false;
// let effectSlide;

// if(window.innerWidth <= 992){
//     scrollDisable = true
// } else {
//     scrollDisable = false
// }

// // turn of block scroll in flat selection
// if(document.getElementById('flat-detail')) {
//     document.getElementById('flat-detail').addEventListener('wheel',function(e) {
//         e.stopPropagation();
//     }, true);
// }

// // turn of block scroll when usere is scrolling through projects
// let projectsScrolled = true;
// // let projectsBlockHeight = $('.projects-grid').height(); // get height of projects block
// let projectsBlockHeight ;
// if (window.innerWidth > 1200){
//     projectsBlockHeight = $(".projects-grid").height(); // get height of projects block
// } else {
//     projectsBlockHeight = $(".projects-grid").height();
// }
// let blockServiceScrolled = true;
// let blockServiceHeight = $("#block_service").height();

// let blockOneScrolled = true;
// let blockOneHeight = $("#block_1").height();

// // height elementu včetně paddingu
// window.addEventListener("load",() => {
//     let windowHeight = window.innerHeight

//     let blockOne = document.getElementById("block_1")
//     blockOneHeight = parseInt(window.getComputedStyle(blockOne).height)-5
//     if (blockOneHeight > windowHeight && window.innerWidth > 991){
//         blockOne.querySelector(".scroll-bottom-space").style.display = "block"
//         blockOneHeight = parseInt(window.getComputedStyle(blockOne).height)-5
//     } else {
//         blockOne.querySelector(".scroll-bottom-space").style.display = "none"
//     }

//     let projectsBlock;
//     if (window.innerWidth > 1200){
//         projectsBlock = document.querySelector(".projects-grid")
//     } else {
//         projectsBlock = document.querySelector(".projects-grid")
//     }
//     console.log(projectsBlock);
//     projectsBlockHeight = parseInt(window.getComputedStyle(projectsBlock).height)-5
//     if (projectsBlockHeight > windowHeight && window.innerWidth > 991){
//         projectsBlock.querySelector(".scroll-bottom-space").style.display = "block"
//         projectsBlockHeight = parseInt(window.getComputedStyle(projectsBlock).height)-5
//     } else {
//         projectsBlock.querySelector(".scroll-bottom-space").style.display = "none"
//     }
//     console.log(projectsBlockHeight);
    
//     let blockService = document.getElementById("block_service")
//     blockServiceHeight = parseInt(window.getComputedStyle(blockService).height)-5
//     if (blockServiceHeight > windowHeight && window.innerWidth > 991){
//         blockService.querySelector(".scroll-bottom-space").style.display = "block"
//         blockServiceHeight = parseInt(window.getComputedStyle(blockService).height)-5
//     } else {
//         blockService.querySelector(".scroll-bottom-space").style.display = "none"
//     }
// })

// // detect when user is scrolling through projects
// $('#scroll-projects').on('mouseenter', function(e) {
//     projectsScrolled = false;
// });
// $('#scroll-projects').on('mouseleave', function(e) {
//     projectsScrolled = true;
// });

// $('#scroll-service').on('mouseenter', function(e) {
//     blockServiceScrolled = false;
// });
// $('#scroll-service').on('mouseleave', function(e) {
//     blockServiceScrolled = true;
// });

// $('#scroll-block1').on('mouseenter', function(e) {
//     blockOneScrolled = false;
// });
// $('#scroll-block1').on('mouseleave', function(e) {
//     blockOneScrolled = true;
// });


// // reload page and scroll to top after resize
// window.onresize = function(e) {
//     //handleReload()
//     let windowHeight = window.innerHeight

//     //obnoví po resizu
//     if(window.innerWidth >= 991){
//         if (window.RT) clearTimeout(window.RT);
//         window.RT = setTimeout(function() {
//             window.scrollTo(0, 0);
//             this.location.reload(false);
//         }, 100);
//     }

//     // let blockOne = document.getElementById("block_1")
//     // blockOneHeight = parseInt(window.getComputedStyle(blockOne).height)-5
//     // if (blockOneHeight > windowHeight && window.innerWidth > 991){
//     //     blockOne.querySelector(".scroll-bottom-space").style.display = "block"
//     //     blockOneHeight = parseInt(window.getComputedStyle(blockOne).height)-5
//     // } else {
//     //     blockOne.querySelector(".scroll-bottom-space").style.display = "none"
//     // }

//     // let projectsBlock;
//     // if (window.innerWidth > 1200){
//     //     projectsBlock = document.querySelector(".projects-grid-side")
//     // } else {
//     //     projectsBlock = document.querySelector("#projects-grid-side-res-smal")
//     // }
//     // console.log(projectsBlock);
//     // projectsBlockHeight = parseInt(window.getComputedStyle(projectsBlock).height)-5
//     // if (projectsBlockHeight > windowHeight && window.innerWidth > 991){
//     //     projectsBlock.querySelector(".scroll-bottom-space").style.display = "block"
//     //     projectsBlockHeight = parseInt(window.getComputedStyle(projectsBlock).height)-5
//     // } else {
//     //     projectsBlock.querySelector(".scroll-bottom-space").style.display = "none"
//     // }
//     // console.log(projectsBlockHeight);
    
//     // let blockService = document.getElementById("block_service")
//     // blockServiceHeight = parseInt(window.getComputedStyle(blockService).height)-5
//     // if (blockServiceHeight > windowHeight && window.innerWidth > 991){
//     //     blockService.querySelector(".scroll-bottom-space").style.display = "block"
//     //     blockServiceHeight = parseInt(window.getComputedStyle(blockService).height)-5
//     // } else {
//     //     blockService.querySelector(".scroll-bottom-space").style.display = "none"
//     // }

//     // if(window.innerWidth <= 991){
//     //     scrollDisable = true
//     // } else {
//     //     scrollDisable = false
//     // }
// }

// window.addEventListener('wheel', (e) => {

//     // přenesení události na element s overflow-y
//     // const projectsGrid = document.querySelector(".projects-grid");

//     // projectsGrid.addEventListener("wheel", (e) => {
//     //     let deltaY = e.deltaY
//     //     const scrollTop = projectsGrid.scrollTop

//     //     console.log("deltaY = " + deltaY);
//     //     console.log("scrollTop = " + scrollTop);

//     //     if(
//     //         (deltaY < 0 && scrollTop === 0) || 
//     //         (deltaY > 0 && projectsGrid.scrollHeight - projectsGrid.clientHeight === scrollTop)
//     //     ){
//     //         e.stopPropagation()
//     //     }
//     // })


//     // throttling delay
//     let delay;
//     if (e.sourceCapabilities && e.sourceCapabilities.firesTouchEvents) {
//         delay = 1250
//     } else {
//         delay = 750
//     }

//     // detect scroll direction
//     let scrollDirection = 1;
//     if (e.wheelDelta) {
//         scrollDirection = e.wheelDelta < 0 ? 1 : -1;
//     } else if (e.deltaY) {
//         scrollDirection = e.deltaY > 0 ? 1 : -1;
//     }

//     // check if user finnished scrolling through projects and allow scrolling through blocks again
//     if((scrollDirection == 1 && $('#scroll-projects').scrollTop() > projectsBlockHeight-window.innerHeight) || (scrollDirection == -1 && $('#scroll-projects').scrollTop() == 0)) {
//         projectsScrolled = true;
//     }
//     if((scrollDirection == 1 && $('#scroll-service').scrollTop() > blockServiceHeight-window.innerHeight) || (scrollDirection == -1 && $('#scroll-service').scrollTop() == 0)) {
//         blockServiceScrolled = true;
//     }
//     if((scrollDirection == 1 && $('#scroll-block1').scrollTop() > blockOneHeight-window.innerHeight) || (scrollDirection == -1 && $('#scroll-block1').scrollTop() == 0)) {
//         blockOneScrolled = true;
//     }

//     if(projectsScrolled && blockServiceScrolled && blockOneScrolled) {
        
//         let windowHeight = window.innerHeight || document.documentElement.clientHeight;
//         let windowPos = window.scrollY;

//         if (!scrollDisable && device === 'desktop' && sidebarsClosed()) {

//             clearInterval(effectSlide);
//             scrollTo(document.documentElement, ((Math.floor(windowPos / windowHeight)) + scrollDirection) * windowHeight, 500); // scroll to next block

//             scrollDisable = true;
//             setTimeout(() => {
//                 scrollDisable = false;
//             }, delay)

//             // reveal()
//         }
//     }
// });

// // aktivuje položku menu když je sekce ve viewportu
// window.addEventListener("scroll", () => { sectionInView() })

// var ts;
// window.addEventListener('touchstart', (e) => { ts = e.touches[0].clientY}, false);

// window.addEventListener('touchend', (e) => swipe(e, ts), false);


// responsive menu control
const hamburger = document.querySelector('.p-hamburger-icon-res');

if (hamburger) {
    hamburger.addEventListener('click', function (e) {
        const menu = document.querySelector('.p-layout__menu-res');
        if (menu) {
            menu.classList.toggle('menu-retracted')
        }
        this.classList.toggle('active');

        window.localStorage.setItem('menu-retracted', window.localStorage.getItem('menu-retracted') == 1 ? 0 : 1);
        e.stopPropagation();
    });

   $('body').on('click', function(e) {
        const menu = document.querySelector('.p-layout__menu-res');
        if (menu) {
            menu.classList.add('menu-retracted')
        }
        $('.p-hamburger-icon-res.active').removeClass('active');
    });

    $('.p-navigation').on('click', function(e) {
        e.stopPropagation();
    });
}

// const layout = document.querySelector('.p-layout');
// const menu = document.querySelector('.p-layout__menu-res');
// if (window.localStorage.getItem('menu-retracted') == 1 && device === 'mobile') {
//     menu.classList.add('menu-retracted');
// } else if (device !== 'mobile') {
//     hamburger.classList.add('active');
// }

// layout.classList.add('rendered');

// setTimeout(() => {
//     menu.classList.add('transition');
// }, 500)


// //slider

// let sliders = document.querySelectorAll('.p-slider');
// if (sliders) {
//   for (let i = 0; i < sliders.length; i++) {
//     let slider = tns({
//       container: sliders[i],
//       items: 1,
//       slideBy: 'page',
//       nav: true,
//       controlsText: ['', ''],
//       mouseDrag: true,
//       gutter: 20,
//     });
//   }
// }

// // scroll to given position
// function scrollTo(element, to, duration) {
//     if (duration <= 0) return;
//     let difference = to - element.scrollTop;
//     let perTick = difference / duration * 10;

//     setTimeout(function() {
//         element.scrollTop = element.scrollTop + perTick;
//         if (element.scrollTop === to) return;
//         scrollTo(element, to, duration - 10);
//     }, 10);
// }


// $(document).ready(function(){

//     // carousel init
//     if($('.p-full-image__image-cont-carousel')) {
//         $('.p-full-image__image-cont-carousel').each(function(index) {
//             $(this).attr('data-slider', index);
//             $(this).slick({
//                 autoplay: true,
//                 arrows: true,
//                 autoplaySpeed: 3000,
//                 dots: true,
//                 slidesToShow: 1,
//                 resposnive: [
//                     {
//                         breakpoint: 991,
//                         settings: {
//                           dots: false,
//                         }
//                     },
//                 ]
//             });

//             $(this).find('.slick-next').html('<img src="/theme/images/sipka3.svg">');
//             $(this).find('.slick-prev').html('<img src="/theme/images/sipka3.svg">');
//         });
//     }

// });

// aktivuje položku menu když je sekce ve viewportu
function sectionInView(){
    const fromTop = window.scrollY
    let navLinkItem = document.querySelectorAll(".p-navigation__item")
    let navLinks = document.querySelectorAll(".p-navigation__link")
    let scrollSections = document.querySelectorAll(".scroll-section")

    for(let i = 0; i < scrollSections.length; i++){
        const scrollSectionTop = scrollSections[i].offsetTop
        const scrollSectionHeight = scrollSections[i].clientHeight
        if (fromTop >= scrollSectionTop && fromTop <= scrollSectionTop + scrollSectionHeight-100) {
            navLinks[i].classList.add("active")
            navLinkItem[i].classList.add("active")
        } else {
            navLinks[i].classList.remove("active")
            navLinkItem[i].classList.remove("active")
        }
    }
}


// // zajistí, aby šlo prokliknout na ovládací prvky karuselu
// // const block1Overlay = document.getElementById("scroll-block1")

// // document.querySelector("swiper-pagination").addEventListener("click", (e) => {
// //     e.stopPropagation();
// // })
// // document.querySelector("swiper-pagination").addEventListener("mousedown", (e) => {
// //     block1Overlay.style.pointerEvents = "none";
// // })
// // document.querySelector("swiper-pagination").addEventListener("mouseup", (e) => {
// //     block1Overlay.style.pointerEvents = "auto";
// // })

// const scroll = new LocomotiveScroll({
//     el: document.querySelector('[data-scroll-container]'),
//     smooth: true
// });

let windowWidth = window.innerWidth;
let wrapper = document.querySelector(".parallax-wrapper");
let content = document.querySelector(".parallax-content");
let spaceToSticky = 0;

function scrollingParalax(e) {
    let wrapperTop = wrapper.getBoundingClientRect().top;
    let contentTop = content.getBoundingClientRect().top;
    let wrapperBottom = wrapper.getBoundingClientRect().bottom;
    let contentBottom = content.getBoundingClientRect().bottom;

    let windowHeight = window.innerHeight;

    // console.log(window.scrollY);
    // console.log(window.innerHeight);

    // console.log("wrapperTop = "+wrapperTop);
    // console.log("contentTop = "+contentTop);

    // console.log("wrapperBottom = "+wrapperBottom);
    // console.log("contentBottom = "+contentBottom);

    // console.log(wrapper.offsetHeight);
    // console.log(content.offsetHeight);

    let distance = 300;
    let speed = 0.4

    position = distance + (wrapperTop - windowHeight)*speed
    if (position < 0){
        position = 0;
    }
    // console.log(position);
    // console.log(wrapperBottom - windowHeight);
    if ((wrapperTop - windowHeight < 0 && wrapperTop < contentTop) || (wrapperTop-windowHeight+distance/speed > 0)){
        content.style.transform = `translateY(${position}px)`
    } else if(wrapperBottom - windowHeight < 0){
        // console.log("under");
        content.style.transform = `translateY(${(wrapperBottom - windowHeight)*speed}px)`
    }

    // sticky parallax content
    if (wrapperTop < 0 && wrapperTop > -spaceToSticky){
        // console.log('sticky');
        content.style.top = `${-wrapperTop}px`
    } else if (wrapperBottom-windowHeight-spaceToSticky < 0 && wrapperBottom-windowHeight > 0){
        // console.log('sticky');
        content.style.top = `${800+(800-(wrapperBottom-windowHeight))}px`
        // console.log(800+(800-(wrapperBottom-windowHeight)));
        // content.style.position = "fixed";
        // content.style.bottom = "0px";
    }
    // console.log(contentBottom-windowHeight);
    // console.log(wrapperBottom-windowHeight);
    
}

let kontaktyBlok1 = document.getElementById("kontakty-blok_1")
let kontaktyBlok2 = document.getElementById("kontakty-blok_2")
let kontaktyBlok3 = document.getElementById("kontakty-blok_3")
let kontaktyMapa = document.getElementById("kontakty-mapa");
let kontaktyImg1 = document.getElementById("kontakty-img_1");
let kontaktyImg2 = document.getElementById("kontakty-img_2");

function scrollKontakty() {
    let kontaktyBlok1Top = kontaktyBlok1.getBoundingClientRect().top
    let kontaktyBlok2Top = kontaktyBlok2.getBoundingClientRect().top
    let kontaktyBlok3Top = kontaktyBlok3.getBoundingClientRect().top
    let windowHeight = window.innerHeight
    if (kontaktyBlok1Top + windowHeight/2 < 0 && kontaktyBlok2Top + windowHeight/2 > 0 && kontaktyBlok3Top + windowHeight/2 > 0){
        kontaktyImg1.style.display = "block"
        kontaktyImg2.style.display = "none"
        kontaktyMapa.style.display = "none"
    } else if (kontaktyBlok1Top + windowHeight/2 < 0 && kontaktyBlok2Top + windowHeight/2 < 0 && kontaktyBlok3Top + windowHeight/2 > 0){
        kontaktyImg1.style.display = "none"
        kontaktyImg2.style.display = "block"
        kontaktyMapa.style.display = "none"
    } else {
        kontaktyImg1.style.display = "none"
        kontaktyImg2.style.display = "none"
        kontaktyMapa.style.display = "block"
    }
}

window.addEventListener("load", () => {
    if(windowWidth > 991){
        wrapper.style.height = `${content.offsetHeight + spaceToSticky*2}px`
        content.style.transform = `translateY(${300}px)`
    }
    if(windowWidth > 991){
        scrollingParalax()
        scrollKontakty()
    }
    sectionInView()
})
window.addEventListener("scroll", (e) => {
    // updateParalaxEffect()
    if(windowWidth > 991){
        scrollingParalax(e)
        scrollKontakty()
        scrollProjekty()
    }
    sectionInView()
});
window.addEventListener("resize", () => {
    if(windowWidth > 991){
        wrapper.style.height = `${content.offsetHeight + spaceToSticky*2}px`
    } else {
        wrapper.style.height = `${content.offsetHeight}px`
    }
    windowWidth = window.innerWidth;
})

let lightbox1 = new SimpleLightbox("#l-box-1 a", {captionSelector: "self"})
let lightbox2 = new SimpleLightbox("#l-box-2 a", {captionSelector: "self"})
let lightbox3 = new SimpleLightbox("#l-box-3 a", {captionSelector: "self"})
let lightbox4 = new SimpleLightbox("#l-box-4 a", {captionSelector: "self"})
let lightbox5 = new SimpleLightbox("#l-box-5 a", {captionSelector: "self"})

let lightbox1res = new SimpleLightbox("#l-box-1r a", {captionSelector: "self"})
let lightbox2res = new SimpleLightbox("#l-box-2r a", {captionSelector: "self"})
let lightbox3res = new SimpleLightbox("#l-box-3r a", {captionSelector: "self"})
let lightbox4res = new SimpleLightbox("#l-box-4r a", {captionSelector: "self"})
let lightbox5res = new SimpleLightbox("#l-box-5r a", {captionSelector: "self"})


// projekty change image

let projektyContainer = document.getElementById('projects-grid-side-res-smal')
let projekty = projektyContainer.querySelectorAll(".project")
let projectImageContainer = document.getElementById("project-img-left-container")
let projectImage = projectImageContainer.querySelector("img")
let projectVideo = projectImageContainer.querySelector("video")
let videoState = false;
let imgSlides;

function scrollProjekty(){
    projekty.forEach((projekt, index) => {
        let projektTop = projekt.getBoundingClientRect().top
        let imgUrl = projekt.dataset.image;
        // let videoUrl = projekt.dataset.video;
        if (projektTop < 500 && projektTop > 0) {
            // imgSlides[index].classList.add('active')
            if (!imgUrl){
                projectImage.style.display = "none"
                projectImage.src = ""
            } else {
                projectImage.style.display = "block"
                projectImage.src = imgUrl
            }

            // if (!videoUrl){
            //     console.log(videoState);
            //     videoState = false;
            //     projectVideo.style.display = "none"
            //     projectVideo.src = ""
            // } else {
            //     if (videoState == false) {
            //         projectVideo.src = videoUrl
            //         projectVideo.style.display = "block"
            //         console.log("změna video url");
            //     }
            //     console.log(videoState);
            //     videoState = true;
            // }
        } else {
            // imgSlides[index].classList.remove('active')
        }
    })
}


let observer = new IntersectionObserver((enteries) => {
    enteries.forEach((entery) => {
        if (entery.isIntersecting) {
            index = $(entery.target).index('.project');
            // console.log(index);
            for(let i = 0; i < imgSlides.length; i ++){
                imgSlides[i].classList.remove("active");
            }
            imgSlides[index].classList.add("active");
        }
    })
}, {threshold: 0.8});

$(document).ready(() => {
    document.querySelectorAll('.project').forEach((item) => {
        observer.observe(item);
    })
})

function createProjectImages(){
    projekty.forEach((projekt) => {
        let imgUrl = projekt.dataset.image;
        let imgSlide = document.createElement("div");
        imgSlide.classList.add('img-slide')
        imgSlide.innerHTML = `<img src='${imgUrl}'>`;
        projectImageContainer.appendChild(imgSlide);
    })
    imgSlides = document.querySelectorAll('.img-slide')
}
createProjectImages()
